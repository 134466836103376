quantity-box {
  display: flex;
  justify-content: space-around;
  align-items:center;
  border: 1px solid var(--black);
  width: 6rem;
  height: 3rem;
  padding: 0 0.75rem;
  svg {
    height: 2.5rem;
    cursor: pointer;
  }
  span#plus, span#minus {
    font-size: 2rem;
    cursor: pointer;
  }
  input {
    font-size: 1.4rem;
    border: none;
    text-align: center;
    width: 100%;
  }
}
