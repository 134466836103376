main#checkout {

  input[type="checkbox"] {
    margin-right: 0.8rem;
  }

  font-size: 1.25rem;

  @media only screen and (min-width: 37.5rem) {
    padding: 0 1rem;
  }

  @media only screen and (min-width: 62rem) {
    padding: 0 7.75rem;
  }

  section#address {

    div#billing_address,
    div#shipping_address {
      width: 100%;
      max-width: 31rem;
      display: grid;
      grid-template-columns: 10rem 1fr;
      align-items: center;
      grid-gap: 1rem;
      margin: 1.25rem 0;

      h3 {
        grid-column: span 2;
      }
    }
  }

  section#payment {
    div#payment_select {
      max-width: 50rem;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 0.6rem;
      column-gap: 0.3rem;
      padding: 0.6rem;

      &.invalid {
        border: 0.0625rem solid var(--red);
      }

      div.paymentmethod {
        border: 1px solid red;
        img {
          width: 3rem;
        }
        input:checked + img {
          border: 2px solid blue;
        }
        input:checked + span {
          border: 1px solid green;
        }

        /* display: grid; */
        /* grid-template-columns: 3rem 5rem; */
        /* grid-template-rows: min-content 1.25rem; */
        /* align-items: center; */
        /* justify-items: center; */
        /**/
        /* input#zahlungsart, */
        /* input#betaalmethode, */
        /* input#paymentmethod { */
        /*   grid-row: 1; */
        /*   grid-column: 1; */
        /* } */
        /**/
        /* img { */
        /*   grid-column: 2; */
        /*   grid-row: 1; */
        /*   max-width: 3, 75rem; */
        /* } */
        /**/
        /* span.name { */
        /*   grid-column: 2; */
        /*   grid-row: 2; */
        /* } */
      }
    }
  }

  strong {
    color: var(--sec);
  }

  section#shipping {
    div.property_list {
      margin: 2, 5rem 0;
      display: grid;
      grid-template-columns: 9.4rem 1fr;
    }
  }

  div.navbuttons {
    margin: 5.5rem 0;
    white-space: nowrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    button {
      margin: 0.125rem;
      white-space: wrap;
      max-width: 50vw;
    }
    @media only screen and (min-width: 37.5rem) {
      display: block;
      button {
        margin: 0 1.5rem 0 0;
      }
    }

    @media only screen and (min-width: 62rem) {}
  }

  div#checkout_items {
    border-top: 0.0625rem solid var(--prim);
    max-width: 62rem;

    &>div {
      display: grid;
      grid-template-columns: 3.75rem 4fr 2fr 1fr 1fr;
      grid-column-gap: 0.6rem;
      align-items: center;
      border-bottom: 0.0625rem solid var(--prim);

      div {
        padding: 0.2rem 0 0.2rem 0;

        input {
          width: 3rem;
        }
      }
    }
  }

  div#checkout_totals {
    margin: 2.5rem 0;

    div {
      span {
        display: inline-block;
      }

      &>span:first-child {
        color: var(--sec);
        font-weight: bold;
        margin-right: 0.7rem;
        width: 12.5rem;

        &::after {
          content: ":";
        }
      }
    }
  }
}
