body {
  header {
    shopping-cart-icon {
      color: var(--sec);
      position: relative;

      a {
        &:visited {
          color: inherit;
        }

        span.badge {
          position: absolute;
          left: 0.4rem;
          right: 0;
          margin-inline: auto;
          width: min-content;
          padding: 0 0.4rem;
          display: inline-block;
          background-color: var(--cta-bg-color);
          color: var(--cta-color);
          border-radius: 50%;
          text-align: center;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.4;
        }

        svg#cart {
          height: 3rem;
          width: 3rem;

          path {
            fill: var(--sec);
          }
        }
      }

      @media only screen and (min-width: 37.5rem) {
        a {
          svg#cart {
            height: 4rem;
            width: 4rem;
          }

          span.badge {
            left: 0.6rem;
            right: 0;
            padding: 0 0.5rem;
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
