.widget.content {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;

  p {
    font-size: 1.25rem;
  }

  &.row {
    flex-direction: row;
  }

  &.center {
    flex-direction: column;
    align-items: center;

    .content {
      text-align: center;
    }

  }

  img {
    &.plant_photo {
      margin: 1rem;
    }

    &.content_photo {
      margin: 1rem;
      max-height: 24rem;
      object-fit: contain;
    }
  }
}

@media only screen and (min-width: 37.5rem) {
  .widget.content {
    flex-direction: row;

    div.content {
      margin: 1rem;
    }

    img {
      &.item_photo {
        max-height: 28rem;
      }

      &.content_photo {
        max-height: 28rem;
        max-width: 28rem;
      }
    }
  }
}

@media only screen and (min-width: 62rem) {

  .widget.content {
    div.content {
      margin: 2rem;
    }

    max-width: 80rem;
    margin: 2rem auto;
  }
}
