:root {
  --invalid-border: 0.2rem solid var(--red);
}

input,
textarea,
select {
  border: 0.06125rem solid var(--sec);
  padding: 0.4rem;
  font-size: 1.125rem;

  &:focus {
    outline: none;

    ol &.invalid {
      border: var(--invalid-border);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=checkbox] {
  &.invalid {
    outline: var(--invalid-border);
  }
}

select {
  background-color: transparent;
}

button,
span.button,
a.button {
  border-radius: var(--button-border-radius);
  border: 0.0625rem solid var(--sec);
  padding: 0.4rem 1.5rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }


  &.cta {
    background-color: var(--cta-bg-color);
    border: none;
    color: var(--cta-color);

    &:hover {
      background-color: var(--cta-hover-bg-color);
      text-decoration: none;
    }
  }
}

.error {
  color: var(--red);
}

.invalid {
  border: var(--invalid-border);
}

a {
  text-decoration: none;

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:active {
    color: inherit;
  }
}
