payment-method {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 0.1rem solid var(--outline-color);
  margin: 0.3rem;
  max-width: 30rem;
  cursor: pointer;
  border-radius: 0.5rem;

  img {
    width: 3rem;
  }

  &:hover {
    border: 0.1rem solid var(--sec);
  }

  &.selected {
    border: 0.4rem solid var(--cta);
    margin: 0;
  }
}
