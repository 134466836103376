order-button {
  --border-radius: 0.3125rem;
  display:flex;
  height: fit-content;
  column-gap: 0;
  input {
    width: 6.25rem;
    padding-left: 0.3125rem;
    border: 0.0625rem solid var(--cta);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  button {
    padding: 0.3125rem 0.625rem;
    background-color: var(--cta-bg-color);
    color: var(--cta-color);
    border: 0.0625rem solid var(--cta);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      background-color: var(--cta-hover-bg-color);
    }
  }
}
