footer {
  margin: 2rem auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: var(--max-width);

  h4 {
    font-family: "Teko", serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: var(--white);
    font-size: 2rem;
    font-weight: normal;
  }

  div {
    display: flex;
    padding: 0.5rem 4rem;
    flex-direction: column;
    font-size: 1.25rem;
    line-height: 1.8rem;

    &#col1 {
      justify-content: space-between;
    }

    #logo-container {
      display: flex;
      padding: 0;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding-top: 2.5rem;

      svg#logo {
        height: 4rem;
        width: 4rem;
      }

      svg#name {
        height: 2.4rem;
        width: 12rem;
      }

      svg {
        fill: var(--white);
      }

    }

    #copy {
      font-size: 0.8rem;
    }
  }


  @media only screen and (min-width: 37.5rem) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    /* grid-template-columns: 1fr 1fr; */
  }

  @media only screen and (min-width: 62rem) {
    /* grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); */
  }
}
