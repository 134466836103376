div#notification {
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  top: 2rem;
  right: -20rem;
  width: 20rem;
  max-width: 100%;
  background-color: var(--white);
  border: 1px solid var(--sec);
  border-radius: 0.2rem;
  padding: 1rem 2rem;

  &.visible {
    animation: movein 0.3s ease forwards, moveout 0.5s 2s ease forwards;
    right: 1rem;
  }

}

@keyframes movein {
  from {
    right: -20rem;
  }

  to {
    right: 1rem;
  }
}

@keyframes moveout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
