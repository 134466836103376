.widget.plant {
  padding: 0.5rem;

  a {
    height: 100%;
    display: grid;
      grid-template-rows: min-content 1fr 1fr 1fr;
      grid-template-columns: 1fr;
    /* grid-template-columns: min-content 1fr; */
    /* grid-template-rows: 2rem 2rem 1fr; */
    align-items: center;
    justify-items: left;

    /* .plant_photo { */
    /*   grid-row: 1 / span 3; */
    /*   grid-column: 1; */
    /*   align-self: start; */
    /* } */
    /**/
    /* .local_name { */
    /*   grid-row: 1; */
    /*   grid-column: 2; */
    /*   font-weight: bold; */
    /*   font-size: 1.2rem; */
    /* } */
    /**/
    /* .latin_name { */
    /*   grid-row: 2; */
    /*   grid-column: 2; */
    /*   font-style: italic; */
    /*   font-size: 1rem; */
    /* } */
    /**/
    /* .button.cta { */
    /*   justify-self: right; */
    /*   grid-row: 3; */
    /*   grid-column: 2; */
    /* } */

      .plant_photo {
        grid-column: 1;
        grid-row: 1;
      }

      .local_name {
        grid-row: 2;
        grid-column: 1;
        font-weight: bold;
        color: var(--sec);
        align-self: end;
        margin-bottom: 0.2rem;
      }

      .latin_name {
        grid-row: 3;
        grid-column: 1;
        font-style: italic;
        align-self: start;
        font-size: 0.9rem;
      }

      .button.cta {
        justify-self: left;
        grid-column: 1;
        grid-row: 4;
      }
    @media only screen and (min-width: 37.5rem) {
      grid-template-rows: min-content 1fr 1fr 1fr;
      grid-template-columns: 1fr;

      .plant_photo {
        grid-column: 1;
        grid-row: 1;
      }

      .local_name {
        grid-row: 2;
        grid-column: 1;
        font-weight: bold;
        color: var(--sec);
        align-self: end;
        margin-bottom: 0.2rem;
      }

      .latin_name {
        grid-row: 3;
        grid-column: 1;
        font-style: italic;
        align-self: start;
        font-size: 0.9rem;
      }

      .button.cta {
        justify-self: left;
        grid-column: 1;
        grid-row: 4;
      }
    }

    @media only screen and (min-width: 62rem) {}
  }
}
