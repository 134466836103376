search-box {
  --border-radius: 0.5rem;

  div.box {
    display: flex;
    flex-direction: row;

    input {
      flex: 1 1 0;
      border: 0.0625rem solid var(--prim);
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      text-align: center;
      /* color: var(--sec); */
        color: var(--prim);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--prim);
      }
    }

    div.right {
      background-color: var(--prim);
      height: 2.92rem;
      width: 2.92rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);

      svg#search {
        cursor: pointer;
        height: 1.625rem;
        width: 1.625rem;

        path {
          fill: var(--white);
        }
      }
    }

  }

  div.results {
    position: absolute;
    display: none;
    flex-direction: column;
    background: var(--white);
    padding: 0.625rem;
    border: 0.0625rem solid var(--gray);
    border-radius: 0.3125rem;
    z-index: 9999;
  }

  @media only screen and (min-width: 37.5rem) {
    width: 100%;
    max-width: 22rem;
  }

  @media only screen and (min-width: 62rem) {
    width: 100%;
    max-width: 40rem;
  }
}
