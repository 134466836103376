html {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, */
  /*   Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; */
  font-size: 12px;
}

h1,
h2 {
  font-family: "Teko", serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--sec);
  margin: 0;
}

h1 {
  font-weight: normal;
  font-size: 5rem;

  small {
    font-size: 3.2rem;
    color: var(--prim);
  }
}

h2 {
  font-weight: normal;
  font-size: 3.5rem;

  small {
    font-size: 2.5rem;
    color: var(--prim);
  }
}

h3 {
  font-weight: bold;
  font-size: 1.875rem;

  small {
    font-size: 1.5rem;
    color: var(--prim);
  }
}


strong {
  color: var(--sec);
}

@media only screen and (min-width: 37.5rem) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 62rem) {
  html {
    font-size: 16px;
  }
}
