section.widget.page {
  border: none;

  p {
    font-size: 1rem;
  }

  img.photo {
    border-radius: 0.5rem;
    margin: 0.3125rem;
    max-width: 9.4rem;
    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }
  @media only screen and (min-width: 37.5rem) {
    img.photo {
      max-width: 18.75rem;
    }
  }
  @media only screen and (min-width: 62rem) {
    img.photo {
      max-width: 25rem;
    }
  }
}
