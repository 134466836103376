body {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);

  main {
    flex-grow: 1;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--grid-column-size), 1fr));
    grid-auto-rows: var(--grid-row-size);
    grid-auto-flow: dense;
    /* gap: 1.5rem; */
  }

  @media only screen and (min-width: 37.5rem) {}

  @media only screen and (min-width: 62rem) {
    main {
      max-width: var(--max-width);
      margin: auto;
    }
  }
}

