section.widget.item {
  /* border: 0.0625rem solid var(--prim); */
  /* >a { */
  /*   display: block; */
  /* } */
  grid-column: 1 / -1;
  grid-row: span 2;
  margin: 1rem;
  display: grid;
  /* grid-template-columns: calc(var(--grid-column-size) * 2) 1fr; */
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  a {
    grid-column: 1;
    grid-row: 1 / span 2;

    .item_photo {
      width:100%;
      height: 100%;
    }
  }

  .info {
    grid-column: 2;
    grid-row: 1;
    padding: 1rem;
  }

  order-button {
    grid-column: 2;
    grid-row: 2;
    margin-left: 1rem;
    align-self: end;
  }

  .local_name {
    font-weight: bold;
    color: var(--sec);
    font-size: 1.6rem;
  }

  /* .latin_name { */
  /*   font-style: italic; */
  /* } */

  .deal {
    position: absolute;
    margin: 0.4rem 0 0 1.4rem;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.3rem 1.1rem;
    color: var(--white);
    background-color: var(--cta-bg-color);
    border-radius: 2rem;
    display: none;
  }

  &[data-tiptop="!"] {
    .deal {
      display: inline-block;
    }
  }


  &.small {}

  &.medium {}

  &.large {}

  @media only screen and (min-width: 37.5rem) {
    grid-row: span 3;
    grid-column: span 3;
    grid-template-columns: 1fr;
    grid-template-rows: 20rem 1fr min-content;
    margin: 2rem 4rem;
    a {
      grid-row: 1;
      img.item_photo {
        margin: 0 1rem;
      }
    }
    .info {
      grid-column: 1;
      grid-row: 2;
    }
    order-button {
      grid-column: 1;
      grid-row: 3;
    }


  }

  @media only screen and (min-width: 62rem) {}
}
