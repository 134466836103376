
  .price {
    &::before {
      content: "€";
      margin-right: 0.3125rem;
    }

    font-size: 1.4rem;
    font-weight: bold;
    color: var(--sec);
  }
