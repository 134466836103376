:root {
  --prim-h: 70;
  --prim-s: 53;
  --prim-l: 42;
  --prim: hsl(var(--prim-h) var(--prim-s) var(--prim-l)); /* #92A433 */
  --sec-h: 74;
  --sec-s: 41;
  --sec-l: 28;
  --sec: hsl(var(--sec-h) var(--sec-s) var(--sec-l)); /* #57652A */
  --white: white;
  --black: hsl(var(--sec-h) calc(var(--sec-s) + 0) calc(var(--sec-l) - 20));
  --gray: gray;
  --red: red;

  --cta-h: 15;
  --cta-s: 82;
  --cta-l: 51;
  --cta: hsl(var(--cta-h) var(--cta-s) var(--cta-l)); 
  /* #e94e1b;  */
  --cta-bg-color: var(--cta);
  --cta-hover-bg-color: hsl(calc(var(--cta-h) - 19) calc(var(--cta-s) - 3) calc(var(--cta-l) - 9)); /* hsl(356 79 42); #be1622; */
  --cta-border-color: hsl(var(--cta-h) var(--cta-s) calc(var(--cta-l) - 15));
  --cta-color: var(--white);
  --outline-color: hsl(var(--prim-h) calc(var(--prim-s) - 10) calc(var(--prim-l) + 40));

  --logo-fill: var(--prim);
  --name-fill: var(--sec);

  --main-bg-color: var(--white);
  --text-color: var(--black);
  --header-bg-color: var(--main-bg-color);
  --header-color: var(--text-color);
  --footer-bg-color: var(--sec);
  --footer-color: var(--white);
}
body {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  header {
    background-color: var(--header-bg-color);
    color: var(--header-color);
  }
  footer {
    background-color: var(--footer-bg-color);
    color: var(--footer-color);
  }
}
