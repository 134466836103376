body {
  header {
    div#topmenu {
      display: grid;
      grid-template-columns: 3rem 1fr 3rem;
      gap: 0.5rem;
      padding: 0.5rem;

      a#logo {
        grid-row: 1;
        grid-column: 1 / span 2;
      }

      shopping-cart-icon {
        grid-row: 1;
        grid-column: 3;
      }

      search-box {
        grid-row: 2;
        grid-column: 1 / span 3;
      }
    }

    div#mainmenu {
      background-color: var(--prim);
      color: white;
      width: 100%;
      max-width: var(--max-width);
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      a {
        font-family: Teko, sans-serif;
        font-size: 2rem;
      }
    }

    @media only screen and (min-width: 37.5rem) {
      height: 11.87rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;

      div#topmenu {
        width: 100%;
        max-width: 78rem;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
      }

      search-box {
        margin-top: 1rem;
      }
    }

    @media only screen and (min-width: 62rem) {}




    a#logo {
      display: flex;
      flex-direction: row;
      column-gap: 0.625rem;
      align-items: center;

      svg#logo {
        height: 3rem;
        width: auto;
        fill: var(--logo-fill);
      }

      svg#name {
        height: 2rem;
        fill: var(--name-fill);
      }

      @media only screen and (min-width: 37.5rem) {
        flex-direction: column;

        svg#logo {
          height: 3.75rem;
        }

        svg#name {
          height: 2.2rem;
        }
      }

      @media only screen and (min-width: 62rem) {
        column-gap: 1.25rem;
        flex-direction: row;

        svg#logo {
          height: 4.5rem;
        }

        svg#name {
          height: 2.44rem;
        }
      }
    }
  }
}
