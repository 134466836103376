img {
  max-width: 100%;

  &.plant_photo {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }

  &.item_photo {
    object-fit: cover;
  }
}

@media only screen and (min-width: 37.5rem) {
  img {
    &.plant_photo {
      width: 12.5rem;
      height: 12.5rem;
    }
  }
}

@media only screen and (min-width: 62rem) {}
