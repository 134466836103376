.properties {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  &.plant {
    div[data-key="property"] {
      font-weight: bold;
      color: var(--sec);
      justify-self: end;
    }
  }

  label {
    font-weight: bold;
    color: var(--sec);
  }


  textarea {
    height: 8rem;
  }

  button.cta {
    max-width: 10rem;
  }
}

@media only screen and (min-width: 37.5rem) {
  .properties {
    grid-template-columns: 1fr 2fr;

    label {
      font-weight: bold;
      color: var(--sec);
      justify-self: end;
    }

    input,
    textarea {
      width: 30rem;
    }
  }
}
