.widget {
  position: relative;
  overflow: hidden;
  grid-column: 1 / -1;
  margin: 1rem;

  &.image {
    color: var(--white);

    h1 {
      color: var(--white);
    }

    p {
      color: var(--white);
    }
  }

  &.prim {
    color: var(--white);
    background-color: var(--prim);
  }

  &.sec {
    color: var(--white);
    background-color: var(--sec);
  }

  &.cta {
    color: var(--cta-color);
    background-color: var(--cta-bg-color);
  }

  &.small {
    grid-row: span 2;
    grid-column: span 2;
  }

  &.medium {
    grid-row: span 3;
    padding: 1rem;
  }

  &.large {
    grid-row: span 4;
    padding: 2rem;

    h1 {
      margin: 0;
    }

  }

  &.hero {
    grid-row: span 3;
    margin: 0;

    h1 {
      font-size: 6rem;
    }

    p {
      font-size: 1.8rem;
    }

    a.button.cta,
    button.cta {
      font-size: 1.8rem;
      font-weight: bold;
      border-radius: 2rem;
      padding: 1rem 1.7rem 1.2rem 1.8rem;

    }
  }

  &.one-row {
    grid-row: span 1;
  }

  &.two-row {
    grid-row: span 2;
  }

  &.three-row {
    grid-row: span 3;
  }

  &.four-row {
    grid-row: span 4;
  }

  &.five-row {
    grid-row: span 5;
  }

  &.six-row {
    grid-row: span 6;
  }

  &.seven-row {
    grid-row: span 7;
  }

  &.eight-row {
    grid-row: span 8;
  }

  &.one-col {
    grid-column: span 1;
  }

  &.two-col {
    grid-column: span 2;
  }

  &.three-col {
    grid-column: span 3;
  }

  &.four-col {
    grid-column: span 4;
  }

  &.five-col {
    grid-column: span 5;
  }

  &.six-col {
    grid-column: span 6;
  }

  &.seven-col {
    grid-column: span 7;
  }

  &.eight-col {
    grid-column: span 8;
  }

  div.half {
    position: relative;
    height: 100%;
    padding: 0.5rem 1rem 1.5rem 1.5rem;

    @media only screen and (min-width: 37.5rem) {
      width: 50%;
    }
  }
}


@media only screen and (min-width: 37.5rem) {
  .widget {
    margin: 2rem;

    &.small {
      grid-row: span 2;
      grid-column: span 2;
    }

    &.medium {
      grid-column: span 3;
    }

    &.large {
      grid-column: span 4;
    }

    &.hero {
      grid-row: span 4;
    }
  }
}

@media only screen and (min-width: 62rem) {
  .widget {
    &.small {
      grid-row: span 2;
      grid-column: span 2;
    }

    &.medium {
      grid-row: span 2;
      grid-column: span 4;
    }

    &.large {
      grid-row: span 4;
      grid-column: span 4;
    }

    &.hero {
      grid-row: span 4;

      h1 {
        font-size: 7.5rem;
      }

      p {
        font-size: 2.0rem;
      }

      a.button.cta,
      button.cta {
        font-size: 2rem;
        font-weight: bold;
        border-radius: 2rem;
        padding: 1.3rem 2rem 1.6rem 2.4rem;
      }
    }
  }
}
