body {
  shopping-cart {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0.5rem;


    div#checkout_items {
      >div {
        display: grid;
        grid-template-columns: 8rem 2fr 1fr;
        column-gap: 1.4rem;
        height: 10rem;
        padding: 1rem 0;
        border-bottom: 1px solid var(--prim);

        img {
          width: 8rem;
          grid-row: 1 / span 3;
          grid-column: 1;
          align-self: center;
        }

        quantity-box {
          grid-column: 2;
          grid-row: 3;
          align-self: end;
        }

        div.item-title {
          grid-column: 2;
          grid-row: 1;
          font-size: 1.25em;
          font-weight: bold;
        }

        div.item-description {
          font-size: 1.1rem;
          grid-column: 2;
          grid-row: 2;
        }

        div.item-price {
          grid-column: 3;
          grid-row: 1;
          justify-self: right;
          font-size: 1.25rem;
          font-weight: bold;
        }

        &.empty {
          grid-template-columns: 1fr;
          font-size: 1.25rem;
        }
      }


    }

    div#checkout_totals {
      border: 1px solid var(--black);
      padding: 1rem;
      min-height: 15rem;
      max-height: 25rem;
      display: flex;
      flex-direction: column;

      div {
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 3fr 1fr;
        font-size: 1.25rem;
      }

      a#checkout-button {
        margin-top: auto;
        width: max-content;
        font-size: 1.25rem;
        border-radius: 2.5rem;
        padding: 0.5rem 1.5rem;
      }
    }

    @media only screen and (min-width: 37.5rem) {
      div#checkout_items {
        >div {
          grid-template-columns: 10rem 2fr 1fr;
          img {
            width: 10rem;
          }
        }
      }

      padding: 0 1.5rem;
    }

    @media only screen and (min-width: 62rem) {
      grid-template-columns: 3fr 2fr;
      gap: 2rem;
      padding: 0 1rem;
    }

  }
}
